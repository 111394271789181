<template>
  <div class="ui-block">
    <div class="tw-mb-1 tw-flex tw-flex-row tw-justify-between tw-items-center">
      <UiLabel :id="id" :label="label" />
      <v-chip @click="$emit('onAction')" label x-small v-if="action.display && action.mode === 'btn'">
        <v-icon x-small left v-if="action.icon">{{ action.icon }}</v-icon>
        {{ action.label }}
      </v-chip>
    </div>
    <slot name="body"></slot>
  </div>
</template>

<script>
import UiLabel from '@/components/UI/Label.vue'

export default {
  components: {
    UiLabel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      required: false,
      default: () => ({
        display: false,
        mode: '',
        label: '',
      }),
    },
  },
}
</script>
