// Data extract from /api/lpe/locale.php

export const userLocales = Object.freeze([
  {
    id: 48,
    name: 'English - Great Britain',
    code: 'en-gb',
    parent: 'en',
    status: '1',
    clock12h: true,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
    identifier: null,
    radarUuid: null,
  },
  {
    id: 76,
    name: 'French - France',
    code: 'fr-fr',
    parent: 'fr',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
    identifier: null,
    radarUuid: null,
  },
])
