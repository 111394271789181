<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="user-create" v-else>
      <UiContainer>
        <div class="tw-flex tw-flex-row">
          <div>
            <span class="tw-text-xl tw-font-semibold tw-ml-6">
              {{ $t(`user.${isUpdateUser ? 'update' : 'create'}.title`) }}
            </span>
            <v-breadcrumbs :items="breadcrumbs" />
          </div>
          <v-btn
            v-if="isUpdateUser"
            :loading="saving"
            :disabled="saving"
            @click="sendUserInvitation"
            small
            class="tw-text-white tw-ml-auto tw-mt-auto tw-px-4"
            color="#449afd"
          >
            {{ $t('button.userInvitation') }}
          </v-btn>
        </div>
        <v-stepper class="user-create__stepper" elevation="0" v-model="currentStep">
          <v-stepper-header class="user-create__stepper__header">
            <v-stepper-step :complete="currentStep > 1" :step="1" :editable="isUpdateUser">
              {{ $t('user.create.step.first.label') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="currentStep > 2" :step="2" :editable="isUpdateUser">
              {{ $t('user.create.step.second.label') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="currentStep > 3" :step="3" :editable="isUpdateUser">
              {{ $t('user.create.step.third.label') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="currentStep > 4" :step="4" :editable="isUpdateUser">
              {{ $t('user.create.step.fourth.label') }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items :class="{ 'theme--dark': $vuetify.theme.dark }" class="user-create__stepper__content">
            <form
              class="tw-flex tw-flex-col tw-justify-center"
              @submit.prevent="onSubmit"
              novalidate
              autocomplete="off"
            >
              <v-stepper-content class="sm:tw-w-2/3 tw-mx-auto" step="1">
                <span class="tw-font-semibold">{{ $t('user.create.step.first.title') }}</span>
                <div class="user-create__stepper__content__grid">
                  <ui-block class="required-asterisk" id="emailLabel" :label="$t('user.create.form.email.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.email"
                        id="email"
                        solo
                        flat
                        outlined
                        dense
                        :placeholder="$t('user.create.form.email.placeholder')"
                        :error-messages="simpleErrors('userModel', 'email')"
                      />
                    </template>
                  </ui-block>
                  <ui-block id="lastNameLabel" :label="$t('user.create.form.lastName.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.lastName"
                        id="lastName"
                        solo
                        flat
                        outlined
                        dense
                        :placeholder="$t('user.create.form.lastName.placeholder')"
                      />
                    </template>
                  </ui-block>
                  <ui-block id="firstNameLabel" :label="$t('user.create.form.firstName.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.firstName"
                        id="firstName"
                        solo
                        flat
                        outlined
                        dense
                        :placeholder="$t('user.create.form.firstName.placeholder')"
                      />
                    </template>
                  </ui-block>
                  <ui-block id="clientLabel" class="required-asterisk" :label="$t('user.create.form.client.label')">
                    <template v-slot:body>
                      <v-text-field
                        :value="client.name"
                        id="client"
                        solo
                        flat
                        dense
                        readonly
                        :placeholder="$t('user.create.form.client.placeholder')"
                        :error-messages="simpleErrors('userModel', 'clients')"
                      />
                    </template>
                  </ui-block>
                  <ui-block
                    id="defaultLocaleIsoCodeLabel"
                    class="required-asterisk"
                    :label="$t('user.create.form.defaultLocaleIsoCode.label')"
                  >
                    <template v-slot:body>
                      <v-autocomplete
                        v-model="userModel.defaultLocaleIsoCode"
                        id="defaultLocaleIsoCode"
                        auto-select-first
                        solo
                        flat
                        outlined
                        dense
                        item-value="code"
                        item-text="name"
                        :items="userLocales"
                        :placeholder="$t('user.create.form.defaultLocaleIsoCode.placeholder')"
                        :error-messages="simpleErrors('userModel', 'defaultLocaleIsoCode')"
                      />
                    </template>
                  </ui-block>
                  <ui-block id="roleLabel" class="required-asterisk" :label="$t('user.create.form.role.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        v-model="userModel.role"
                        auto-select-first
                        id="role"
                        solo
                        flat
                        outlined
                        dense
                        item-value="name"
                        item-text="value"
                        :items="roles"
                        :placeholder="$t('user.create.form.role.placeholder')"
                        :error-messages="simpleErrors('userModel', 'role')"
                        @change="updateUserMenus"
                      />
                    </template>
                  </ui-block>
                </div>
                <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                  <v-btn
                    :loading="saving"
                    :disabled="saving"
                    @click="checkUserAndGetAssociations"
                    class="tw-text-white tw-ml-auto tw-px-8"
                    color="#449afd"
                  >
                    {{ $t('button.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div>
                  <UiBanner fluid :title="$t('user.create.step.second.title')">
                    <template v-slot:actions>
                      <div class="tw-flex tw-items-center">
                        <v-text-field
                          v-model="search"
                          :value="search"
                          :prepend-inner-icon="icons.mdiMagnify"
                          :label="$t('user.create.search')"
                          hide-details
                          outlined
                          solo
                          flat
                          dense
                          clearable
                        />
                      </div>
                    </template>
                  </UiBanner>
                  <v-data-table
                    :value="selectedAssociations"
                    @input="assignSelectedAssociations"
                    height="400"
                    class="user-create__stepper__content__grid__table"
                    :item-key="associationsItemKey"
                    :show-select="associations.length > 0"
                    fixed-header
                    disable-pagination
                    hide-default-footer
                    :headers="paramsForSelectOrigin.headers"
                    :items="associations"
                    :search="search"
                    :loading="loadAssociation"
                  >
                    <template v-slot:no-data>
                      {{ $t('user.create.form.associations.noData') }}
                    </template>

                    <template v-slot:[`item.company`]="{ item }">
                      <div class="tw-font-semibold">{{ item.company }}</div>
                      <div>{{ `${item.street}, ${item.post_code} ${item.city}` }}</div>
                    </template>

                    <template v-slot:[`item.enabled`]="{ item }">
                      <v-icon v-if="item.enabled === 1" color="success">{{ icons.mdiCheckCircle }}</v-icon>
                      <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
                    </template>
                  </v-data-table>
                </div>
                <div class="sm:tw-w-2/3 tw-mx-auto tw-my-8 tw-flex tw-flex-row tw-justify-between">
                  <v-btn @click="currentStep -= 1" text class="tw-px-8">
                    {{ $t('button.prev') }}
                  </v-btn>
                  <v-btn @click="currentStep += 1" class="tw-px-8" color="#449afd" dark>
                    {{ $t('button.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <span class="tw-font-semibold">{{ $t('user.create.step.third.modules.title') }}</span>
                <v-radio-group class="tw-ml-3 tw-flex tw-w-full" row v-model="selectedModules" mandatory>
                  <v-radio
                    class="tw-w-1/3 sm:tw-w-1/4 tw-mt-2 tw-m-0"
                    v-for="(value, idx) in modules"
                    :key="`module-${idx}`"
                    :label="value.module"
                    :value="value.module"
                    @change="assignSelectedModule"
                  ></v-radio>
                </v-radio-group>
                <span class="tw-font-semibold">{{ $t('user.create.step.third.title') }}</span>
                <div class="tw-mt-3 tw-overflow-y-scroll tw-max-h-72">
                  <v-treeview
                    open-all
                    v-model="selectedMenus"
                    :items="menus"
                    item-children="childMenus"
                    item-key="slug"
                    selection-type="leaf"
                    selectable
                    return-object
                    @input="assignSelectedMenus"
                  >
                    <template v-slot:label="{ item }">
                      {{ item.label }}
                      <v-chip v-if="!item.parentMenu" label small>
                        {{ item.module }}
                      </v-chip>
                    </template>
                  </v-treeview>
                </div>
                <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                  <v-btn @click="currentStep--" text class="tw-px-8">
                    {{ $t('button.prev') }}
                  </v-btn>
                  <v-btn @click="currentStep++" class="tw-px-8" color="#449afd" dark>
                    {{ $t('button.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content class="sm:tw-w-2/3 tw-mx-auto" step="4">
                <span class="tw-font-semibold">{{ $t('user.create.step.fourth.title') }}</span>
                <div class="user-create__stepper__content__grid">
                  <ui-block id="emailRecapLabel" :label="$t('user.create.form.email.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.email"
                        id="emailRecap"
                        solo
                        outlined
                        flat
                        disabled
                        readonly
                        dense
                      />
                    </template>
                  </ui-block>
                  <ui-block id="lastNameRecapLabel" :label="$t('user.create.form.lastName.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.lastName"
                        id="lastNameRecap"
                        solo
                        outlined
                        flat
                        disabled
                        readonly
                        dense
                      />
                    </template>
                  </ui-block>
                  <ui-block id="firstNameRecapLabel" :label="$t('user.create.form.firstName.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.firstName"
                        id="firstNameRecap"
                        solo
                        outlined
                        flat
                        disabled
                        readonly
                        dense
                      />
                    </template>
                  </ui-block>
                  <ui-block id="clientRecapLabel" :label="$t('user.create.form.client.label')">
                    <template v-slot:body>
                      <v-text-field v-model="client.name" id="clientRecap" solo outlined flat disabled readonly dense />
                    </template>
                  </ui-block>
                  <ui-block
                    id="defaultLocaleIsoCodeRecapLabel"
                    :label="$t('user.create.form.defaultLocaleIsoCode.label')"
                  >
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.defaultLocaleIsoCode"
                        id="defaultLocaleIsoCodeRecap"
                        solo
                        outlined
                        flat
                        disabled
                        readonly
                        dense
                      />
                    </template>
                  </ui-block>
                  <ui-block id="roleRecapLabel" :label="$t('user.create.form.role.label')">
                    <template v-slot:body>
                      <v-text-field
                        v-model="userModel.role"
                        id="roleRecap"
                        solo
                        outlined
                        flat
                        disabled
                        readonly
                        dense
                      />
                    </template>
                  </ui-block>
                </div>
                <div>
                  <div class="tw-font-semibold tw-text-base tw-my-2">
                    {{ $t('user.create.step.second.label') }}
                  </div>
                  <v-data-table
                    height="300"
                    dense
                    item-key="id"
                    fixed-header
                    disable-pagination
                    hide-default-footer
                    :headers="paramsForSelectOrigin.headers"
                    :items="selectedAssociations"
                  >
                    <template v-slot:[`item.company`]="{ item }">
                      <div class="tw-font-semibold">{{ item.company }}</div>
                      <div>{{ `${item.street}, ${item.post_code} ${item.city}` }}</div>
                    </template>

                    <template v-slot:[`item.enabled`]="{ item }">
                      <v-icon v-if="item.enabled === 1" color="success">{{ icons.mdiCheckCircle }}</v-icon>
                      <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
                    </template>
                  </v-data-table>
                </div>
                <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                  <v-btn @click="currentStep -= 1" text class="tw-px-8">
                    {{ $t('button.prev') }}
                  </v-btn>
                  <v-btn
                    :loading="saving"
                    :disabled="saving"
                    type="submit"
                    class="tw-text-white tw-ml-auto tw-px-8"
                    color="#449afd"
                  >
                    {{ $t(`button.${isUpdateUser ? 'save' : 'create'}`) }}
                  </v-btn>
                </div>
              </v-stepper-content>
            </form>
          </v-stepper-items>
        </v-stepper>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiSortCalendarDescending,
  mdiSortCalendarAscending,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiMagnify,
} from '@mdi/js'
import googleRecaptchaMixin from '@/mixins/google-recaptcha.mixin'
import ErrorsMixin from '@/mixins/errors.mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { userLocales } from '@/config/userLocales.config'

import UiBlock from '@/components/UI/Block.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'UserManage',
  components: {
    UiBlock,
    UiBanner,
    UiContainer,
  },
  mixins: [validationMixin, ErrorsMixin, googleRecaptchaMixin],
  data() {
    return {
      userLocales,
      icons: {
        mdiSortCalendarDescending,
        mdiSortCalendarAscending,
        mdiCheckCircle,
        mdiCloseCircle,
        mdiMagnify,
      },
      breadcrumbs: [
        {
          text: this.$t('user.create.breadcrumbs.list'),
          to: { name: 'UserList' },
          exact: true,
        },
        {
          text: this.$t('user.create.breadcrumbs.create'),
          to: { name: 'UserManage' },
          exact: false,
        },
      ],
      saving: false,
      userModel: {
        email: null,
        firstName: '',
        lastName: '',
        defaultLocaleIsoCode: 'fr-fr',
        role: '',
        clients: '',
        locationIDListLegacy: [],
        menusLegacy: [],
        defaultModule: '',
      },
      loadAssociation: false,
      selectedAssociations: [],
      selectedModules: '',
      selectedMenus: [],
      selectionType: 'independent',
      currentStep: 1,
      search: '',
    }
  },
  async created() {
    this.setUpdating(true)
    await this.initRecaptcha()

    try {
      await Promise.all([this.getRoles(), this.isEditRoute && this.getUser({ params: { id: this.$route.params.id } })])
      await this.getUserMenus(this.currentUser ? this.currentUser.role : '')
    } catch (error) {
      this.showNotificationError()
      this.$router.push({ name: 'UserList' })
    }

    if (this.isUpdateUser) {
      const {
        email,
        firstName,
        lastName,
        defaultLocaleIsoCode,
        clients,
        role,
        locationIDListLegacy,
        defaultModule,
        menusLegacy,
      } = this.currentUser
      this.userModel = {
        ...this.userModel,
        email,
        firstName,
        lastName,
        defaultLocaleIsoCode,
        clients,
        role,
        locationIDListLegacy,
        defaultModule,
        menusLegacy,
      }
      this.selectedMenus = this.userModel.menusLegacy.map(menuL => this.findMenu(menuL, this.menus))
      this.selectedModules = this.userModel.defaultModule
    } else {
      this.userModel.role = this.roles[0].name
      this.userModel.clients = [this.client.identifier]
    }

    this.setUpdating(false)
  },
  watch: {
    async currentStep(currStep) {
      if (currStep === 1) this.resetUserAssociation()
      if (currStep === 2) {
        this.loadAssociation = true
        await this.getUserAssociation({
          query: [
            { key: 'brandId', value: this.client.brandId },
            { key: 'clientId', value: this.client.clientId },
            { key: 'group', value: this.userModel.role },
          ],
        })

        this.selectedAssociations = this.associations.filter(association =>
          this.userModel.locationIDListLegacy.includes(association[this.paramsForSelectOrigin.key]?.toString())
        )

        if (this.selectedAssociations.length === 0) {
          this.assignSelectedAssociations([])
        }
        this.loadAssociation = false
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser,
      roles: state => state.users.roles,
      associations: state => state.users.associations,
      menus: state => state.users.menus,
      client: state => state.backoffice.currentClient,
      updating: state => state.backoffice.updating,
      clients: state => state.backoffice.brands,
      modules: state => state.backoffice.modules,
    }),
    associationsItemKey() {
      switch (this.userModel.role) {
        case 'GROUP_ADMIN':
          return 'shop_group'
        default:
          return 'id'
      }
    },
    paramsForSelectOrigin() {
      const origin = {
        GROUP_ADMIN: {
          key: 'shop_group',
          headers: [
            {
              text: this.$t('user.create.list.headers.name'),
              value: 'shop_group',
              sortable: true,
            },
            {
              text: this.$t('user.create.list.headers.count'),
              value: 'count',
              sortable: true,
            },
          ],
        },
        LOCATION: {
          key: 'id',
          headers: [
            {
              text: this.$t('user.create.list.headers.location'),
              value: 'company',
              sortable: true,
            },
            {
              text: this.$t('user.create.list.headers.status'),
              value: 'enabled',
              sortable: true,
            },
          ],
        },
      }
      return origin[this.userModel.role] || { key: '', headers: [] }
    },
    isUpdateUser() {
      return this.currentUser && this.isEditRoute
    },
    isEditRoute() {
      return this.$route.name === 'UserEdit'
    },
  },
  methods: {
    ...mapActions({
      getRoles: 'users/getRoles',
      getUserAssociation: 'users/getUserAssociation',
      resetUserAssociation: 'users/resetUserAssociation',
      getUser: 'users/getUser',
      postUser: 'users/postUser',
      patchUser: 'users/patchUser',
      getUserMenus: 'users/getUserMenus',
      postUserInvitation: 'users/postUserInvitation',
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
    }),
    updateUserMenus() {
      this.getUserMenus(this.userModel.role)
    },
    assignSelectedAssociations(item) {
      this.selectedAssociations = item
      this.userModel.locationIDListLegacy = item.map(e => e[this.paramsForSelectOrigin.key])
    },
    assignSelectedModule() {
      this.userModel.defaultModule = this.selectedModules
    },
    assignSelectedMenus() {
      this.userModel.menusLegacy = this.selectedMenus.map(menu => menu.slug)
    },
    async checkUserAndGetAssociations() {
      this.saving = true
      const errors = await this.fieldsHaveErrors('userModel', ['email', 'defaultLocaleIsoCode', 'role', 'clients'])
      if (!errors) {
        if (this.userModel.role === 'BRAND_ADMIN' && this.currentStep === 1) {
          this.currentStep = 3
        } else {
          this.currentStep++
        }
      }
      this.saving = false
    },
    async sendUserInvitation() {
      this.saving = true
      try {
        const token = await this.$recaptcha('forgetPassword')
        await this.postUserInvitation({ email: this.userModel.email, token })
        this.setAlert({
          color: 'success',
          text: this.$t('user.invite.notification.success'),
        })
        this.saving = false
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
        this.saving = false
      }
    },
    async onSubmit() {
      this.userModel.defaultModule = this.selectedModules
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        try {
          if (this.isUpdateUser) {
            await this.patchUser({ params: { id: this.currentUser.uuid }, body: this.userModel })
          } else {
            await this.postUser(this.userModel)
          }
          this.showNotificationSuccess()
          this.$router.push({ name: 'UserList' })
        } catch (e) {
          if (e.status === 422) {
            this.setAlert({
              color: 'error',
              text: this.$t('notification.error.email.duplicate'),
            })
          } else {
            this.showNotificationError()
          }
        }
        this.saving = false
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t(`notification.${this.isUpdateUser ? 'update' : 'create'}.user`, {
          name: `${this.userModel.lastName} ${this.userModel.firstName}`,
        }),
      })
      this.saving = false
    },
    findMenu(slug, array) {
      for (const node of array) {
        if (node.slug === slug) return node
        if (node.childMenus) {
          const child = this.findMenu(slug, node.childMenus)
          if (child) return child
        }
      }
    },
  },
  validations() {
    return {
      userModel: {
        email: {
          required,
        },
        defaultLocaleIsoCode: {
          required,
        },
        clients: {
          required,
        },
        role: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.user-create {
  @apply tw-h-full tw-px-0 tw-py-4 sm:tw-px-8 sm:tw-py-8;

  &__stepper {
    @apply tw-my-0;
    background-color: inherit !important;

    &__header {
      box-shadow: none !important;
    }

    &__content {
      @apply tw-mx-0 tw-mt-0 sm:tw-mt-6 sm:tw-mx-4;
      background-color: $white;

      &.theme--dark {
        background-color: $gray-cod;
      }

      &__grid {
        @apply tw-mx-auto tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-my-4;
      }
    }
  }
}
</style>
