const ErrorsMixin = {
  methods: {
    simpleErrors(ObjectModel, fieldName) {
      const errors = []
      if (!this.$v[ObjectModel][fieldName].$dirty) return errors
      !this.$v[ObjectModel][fieldName].required && errors.push(this.$t('error.required'))
      return errors
    },
    async fieldsHaveErrors(ObjectModel, variablesRequired) {
      let errors = false
      await variablesRequired.forEach(variable => {
        this.$v[ObjectModel][variable].$touch()
        const resultErrors = this.simpleErrors(ObjectModel, variable)
        if (resultErrors.length) {
          errors = true
        }
      })
      return errors
    },
  },
}

export default ErrorsMixin
