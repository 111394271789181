import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import { RECAPTCHA_SITE_KEY } from '@/config/google-keys.config'

const googleRecaptchaMixin = {
  methods: {
    initRecaptcha() {
      Vue.use(VueReCaptcha, {
        siteKey: RECAPTCHA_SITE_KEY,
      })
      this.$recaptchaLoaded()
    },
  },
}

export default googleRecaptchaMixin
